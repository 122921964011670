import { defineMessages } from 'react-intl';

export default defineMessages({
  brand: {
    id: 'configurator.brand',
    defaultMessage: 'Marque',
  },
  model: {
    id: 'configurator.model',
    defaultMessage: 'Modèle',
  },
  year: {
    id: 'configurator.year',
    defaultMessage: 'Année de fabrication',
  },
  contactText: {
    id: 'configurator.contact-text',
    defaultMessage: 'Contactez notre service client par mail info@ipone.fr pour connaître les produits spécifiques adaptés à votre véhicule.'
  },
  contactButton: {
    id: 'configurator.contact-button',
    defaultMessage: 'Nous contacter'
  },
  bestChoice: {
    id: 'configurator.best-choice',
    defaultMessage: '#1 meilleur choix'
  },
  changeModel: {
    id: 'configurator.change-model',
    defaultMessage: 'Changer de modèle'
  },
  search: {
    id: 'configurator.search',
    defaultMessage: 'Rechercher'
  },
  famousBrands: {
    id: 'configurator.famous-brands',
    defaultMessage: 'Marques fréquentes'
  }
});
