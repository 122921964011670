import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .bigtitle {
    position: relative;
    margin: 30px;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: -30px;
      display: inline-block;
      width: 17px;
      height: 25px;
      background: #E1251B;
      transform: skew(-20deg);
    }
  }

  .gatsby-image-wrapper {
    max-height: 300px;
    min-height: 220px;
  }
`;