// @flow

import React from 'react';
import { Link } from 'gatsby';
import { isMobileOnly } from 'react-device-detect';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import ProductItem from '../../eshop/ProductItem';

import { Icon } from '../../icons';

import LubIcon from '../../../icons/Lub.svg';
import ToolIcon from '../../../icons/Tool.svg';

import trads from '../trads';

import {
  Wrapper,
  BrandBox,
  Subtitle,
  Section,
} from './index.styles';

const renderUsage = (uses = []) => {
  return (uses || []).map(c => (
    <span
      key={c.type}
      className="use"
    >
      <Icon src={ToolIcon} />
      {c.type}{c.intervals && c.intervals.length ? ':' : null} {c.intervals.join(', ')}
    </span>
  ));
}

const renderCapacity = (capacities = []) => {
  return (capacities || []).map(c => (
    <span
      key={c}
      className="capacity"
    >
      <Icon src={LubIcon} /> {c}
    </span>
  ));
};

const RenderProducts = ({ section, showSticker }) => {
  const intl = useIntl();
  const { products = [] } = section;

  if (!products?.length) {
    return (
      <div className="product-grid">
        <div className="contact-us">
          {intl.formatMessage(trads.contactText)}
        </div>
      </div>
    )
  }

  let numberOne = null;

  if (products.length > 1 && showSticker) {
    numberOne = (
      <div className="product-grid nomargin">
        <div className="bestchoice">{intl.formatMessage(trads.bestChoice)}</div>
      </div>
    )
  }

  return (
    <div className="products-wrapper">
      {numberOne}
      <div className="product-grid">
        {(products || []).map((p, idx) => (
          <ProductItem
            key={idx}
            {...p}
          />
        ))}
      </div>
    </div>
  );
};

const renderSection = (section, idx) => {
  const { use, capacities } = section;

  return (
    <Section
      key={idx}
      className={classnames({
        'is-mobile': isMobileOnly,
      })}
    >
      <Subtitle>{section.name}</Subtitle>
      <div className="settings">
        {renderCapacity(capacities)}
      </div>
      <div className="settings">
        {renderUsage(use)}
      </div>
      <RenderProducts
        section={section}
        showSticker={idx === 0}
      />
    </Section>
  );
};

const ConfiguratorModel = ({
  model,
  rootPath
}) => {
  const intl = useIntl();
  const { vehicle, sections } = model;
  const { yearrange } = vehicle;

  return (
    <Wrapper
      className={classnames({
        'is-mobile': isMobileOnly,
      })}
    >
      <div className="container">
        <BrandBox>
          <div className="logo">
            <img
              src={`${process.env.GCP_STORAGE}logos/${vehicle.brandId}.png`}
              alt={vehicle.brand}
              loading="lazy"
            />
          </div>
          <div className="content">
            <div className="brand">{vehicle.brand}</div>
            <span>
              {vehicle.model} {yearrange.from || yearrange.to ? `(${yearrange.from} ${yearrange.to ? `- ${yearrange.to}`: ''})` : null}
            </span>
          </div>
          <div className="change">
            <Link
              to={`${rootPath}/${vehicle.brandId}`}
              state={{
                modelName: vehicle.model,
              }}
            >
              {intl.formatMessage(trads.changeModel)}
            </Link>
          </div>
        </BrandBox>
        {sections.map(renderSection)}
      </div>
    </Wrapper>
  );
}

export default ConfiguratorModel;
