// @flow

import React from 'react';

import {
  Wrapper,
} from './Header.styles';


const ConfiguratorHeader = ({
  title
}) => {
  return (
    <Wrapper>
      <div className="content">
        <div className="container">
          <h1 className="bigtitle">
            {title}
          </h1>
        </div>
      </div>
    </Wrapper>
  );
}

export default ConfiguratorHeader;