import styled from 'styled-components';

import { device } from '../../../utils';

export const Subtitle = styled.h2`
  font-weight: bold;
  font-size: 19px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 15px;
`;

export const BrandBox = styled.div`
  background-color: #fafafa;
  padding: 25px 50px;
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  .logo {
    flex: 0 0 60px;

    img {
      height: 60px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    /* margin-right: 60px; */
    line-height: 1.6em;
    justify-content: center;
  }

  .brand {
    text-transform: uppercase;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }

  .change {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      border-radius: 30px;
      background-color: #c80f05;
      font-size: 15px;
      color: #FFF;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 500;
      
      transition: background-color .3s;

      &:hover {
        background-color: #b30e05;
      }
    }
  }
`;

export const Section = styled.div`
  margin: 35px 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  .settings {
    display: block;
    margin-bottom: 10px;

    span {
      svg {
        margin-right: 2px;
        height: 12px;
        width: 14px;
      }

      vertical-align: middle;
      margin-right: 15px;
      font-size: 14px;
    }
  }

  .products-wrapper {
    margin: 30px 0;
  }

  .product-grid {
    .bestchoice {
      font-family: 'Montserrat';
      background-color: #c80f05;
      font-size: 11px;
      font-weight: bold;
      font-style: italic;
      color: #FFF;
      text-transform: uppercase;
      padding: 5px 10px;

      @media ${device.tablet} {
        font-size: 14px;
      }
    }
  }

  &.is-mobile {
    ${Subtitle} {
      font-size: 16px;
      margin-top: 20px;
    }

    ${BrandBox} {
      padding: 15px 30px 50px 30px;
      position: relative;
      margin: 20px auto;

      .content {
        font-size: 14px;
        padding-left: 20px;
        line-height: 1.3em;
      }
  
      .change {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
  
        a {
          margin: 0 auto;
        }
      }
    }
    .settings {
      span {
        display: block;
        font-size: 11px;
        margin-bottom: 5px;
        margin-right: 0;
        padding-left: 18px;
        position: relative;

        svg {
          height: 9px;
          position: absolute;
          left: 0;
          top: 0px;
        }
      }
    }
  }

  .contact-us {
    background-color: #fafafa;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    line-height: 1.5em;

    a {
      text-decoration: none;
      border-radius: 30px;
      background-color: #c80f05;
      font-size: 15px;
      color: #FFF;
      padding: 7px 20px;
      margin-bottom: 0;
      position: relative;
      text-align: center;
      
      transition: background-color .3s;

      &:hover {
        background-color: #b30e05;
      }
    }
  }
`;
