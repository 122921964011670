import { defineMessages } from 'react-intl';

export default defineMessages({
  configuratorTitle: {
    id: 'configurator.title',
    defaultMessage: 'Trouvez{lineBreak} la meilleure huile{lineBreak} pour votre moto :',
  },
  configuratorModelTitle: {
    id: 'configurator.model.title',
    defaultMessage: 'La meilleure huile{lineBreak} pour {model}',
  },
});
