import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Model from '../../components/configurator/model';
import Header from '../../screens/configurator/Header';

import SEO from '../../components/seo';
import { Product } from '../../utils';
import { trackProductList } from '../../utils/gtm';

import trads from '../../screens/configurator/trads';

const Wrapper = styled.div`
  width: 100%;
`;

const ConfiguratorModel = ({
  data,
  pageContext
}) => {
  const intl = useIntl();
  const { rootPath } = pageContext;

  const { model } = data || {};
  const { vehicle } = model;

  const products = (data?.products?.nodes || []).map(p => Product(p));
  let productPosition = 0;
  const displayedProducts = [];
  const sections = model.sections.map(section => ({
    ...section,
    products: section.products
      .map(({ shopifyId, viscosity: variantName }) => {
        const product = products.find(p => p.shopifyId === shopifyId);
        if (!product) {
          return null;
        }

        const variant = product.variants.find(v => {
          return v.selectedOptions.find(o => {
            return o.value === variantName;
          });
        });

        if (!variant) {
          return product;
        }

        return {
          ...product,
          // urlParams: {
          //   'Viscosité': variantName
          // },
          availableForSale: variant.availableForSale,
          defaultVariant: variant,
          title: `${product.title} ${variantName}`,
          thumbnail: {
            ...product.thumbnail,
            originalSrc: variant.thumbnail?.originalSrc
          }
        };
      })
      .filter(p => p)
      .map(p => ({
        ...p,
        trackList: 'Configurator',
        trackPosition: ++productPosition,
      }))
      .map(p => {
        displayedProducts.push(p);
        return p;
      })
  }));

  trackProductList(displayedProducts.map(p => ({
    sku: p.defaultVariant?.sku,
    name: p.title,
    price: p.defaultVariant?.price,
    variant: p.defaultVariant?.title,
    category: p.productType,
    list: p.trackList,
    position: p.trackPosition
  })));

  const title = intl.formatMessage(trads.configuratorModelTitle, {
    lineBreak: <br />,
    model: `${vehicle.brand} ${vehicle.model}`
  });
  
  return (
    <Wrapper>
      <SEO
        title={intl.formatMessage(trads.configuratorModelTitle, {
          lineBreak: '',
          model: `${vehicle.brand} ${vehicle.model}`
        })}
      />
      <Header
        title={title}
      />
      <Model
        model={{
          ...model,
          sections
        }}
        rootPath={rootPath}
      />
    </Wrapper>
  );
}

export const query = graphql`
  query(
    $slug: String!,
    $locale: String!,
    $fetchProducts: [String!]
  ) {
    model: configuratorModelsJson(
      slug: { eq: $slug },
      locale: { eq: $locale }
    ) {
      id
      sections {
        capacities
        name
        products {
          shopifyId
          viscosity
        }
        use {
          intervals
          type
        }
      }
      vehicle {
        brand
        brandId
        model
        yearrange {
          from
          to
        }
      }
    }
    products: allShopifyProduct(
      filter: {
        shopifyId: { in: $fetchProducts },
        locale: { eq: $locale }
      }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        variants {
          availableForSale
          compareAtPrice
          image {
            originalSrc
            altText
          }
          viscosity
          price
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`

export default ConfiguratorModel;